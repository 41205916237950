import React from 'react';

import FeaturedDealsSection from '../components/FeaturedDealsSection';
import Hero from '../components/Hero';
import PartnerStoreSection from '../components/PartnerStoreSection';

function Home() {
	return (
		<>
			<Hero />
			<FeaturedDealsSection />
			<PartnerStoreSection />
		</>
	);
}

export default Home;
